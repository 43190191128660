/** @jsx jsx */
import { ReactElement } from 'react';
import { jsx, Box, Flex, Themed, ThemeUIStyleObject } from 'theme-ui';
import { SocialLink as SocialLinkType } from '@sprinklr/shared-lib/@types/entities';
import { Container } from '@sprinklr/shared-lib';
import { SocialLink } from '@sprinklr/shared-lib';

const LogoSX = {
  height: ['50px', '73px'],
  width: ['50px', '73px'],
  mx: ['auto'],
};

export interface SocialLinksTemplateProps {
  sectionId?: string;
  headline?: string;
  socialLinks?: SocialLinkType[];
  rootSx?: ThemeUIStyleObject;
}

const SocialLinksTemplate = ({
  sectionId,
  headline,
  socialLinks,
  rootSx,
}: SocialLinksTemplateProps): ReactElement => (
  <Box as="section" id={sectionId} sx={rootSx} className={'scn-social-links'}>
    <Box
      sx={{
        backgroundColor: 'rgb(46 56 65 / 90%)',
      }}
    >
      <Container isBodyContainer={false}>
        <Flex
          sx={{
            alignItems: 'center',
            color: 'textSecondary',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            flexWrap: ['wrap'],
          }}
        >
          <Themed.div
            sx={{
              textAlign: 'center',
              color: 'textSecondary',
              width: '100%',
              fontSize: '28px',
              fontWeight: 700,
            }}
          >
            {headline}
          </Themed.div>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              width: ['100%'],
              maxWidth: ['550px'],
            }}
          >
            {socialLinks.map(socialLink => {
              return (
                <SocialLink
                  key={socialLink.channelType}
                  socialLink={socialLink}
                  LogoSX={LogoSX}
                />
              );
            })}
          </Box>
        </Flex>
      </Container>
    </Box>
  </Box>
);

export default SocialLinksTemplate;
